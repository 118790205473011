import React from 'react';
import './App.css';
import Resume from './resume';

function App() {
  return (
    <Resume></Resume>
  );
}

export default App;
